
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      locationRoute: false,
      locationGated: false,
      completeProfile: true,
      goodToGo: false
    }
  },
  computed: {
    ...mapState([
      'location',
      'loading',
      'errors',
      'showCart',
      'token',
      'orderConfirming',
      'shouldOpenConfirmLocationModal',
      'headerHeight',
      'order',
      'user',
      'channel'
    ]),
    ...mapGetters(['coldSignin']),
    mobile() {
      return this.$mq === 'sm' || this.$mq === 'md'
    },
    onMenu() {
      return (
        this.$route.name === 'order-product' ||
        this.$route.name === 'order-checkout' ||
        this.$route.name === 'order-items' ||
        this.$route.name === 'account-login'
      )
    },
    pageClass() {
      const pageClass = this.$route.path.replace(/^\/|\/$/g, '').replace(/\//g, '-')
      if (pageClass) {
        return 'page-'+pageClass
      } else {
        return 'page-home'
      }
    }
  },

  beforeCreate() {
    if (process.client) {
      const currentUrl = window.location.href;
      if (
        currentUrl === 'http://localhost:2001/' ||
        currentUrl === 'https://cateringsandbox--bojangles.netlify.app/' ||
        currentUrl === 'https://catering--bojangles.netlify.app/' ||
        currentUrl === 'https://catering.bojangles.com/'
      ) {
        console.log('reidrect', currentUrl)
        // window.location.assign('/locations');
      }
    }

  },

  beforeMount() {
    this.checkChannel()

    // For catering from app view, set a cookie
    if (this.$route.query.source === 'app') {
      document.cookie = `source=app; path=/;`
    }

    // Prevents unsightly outline on button click
    document.addEventListener('click', (e) => {
      if (e.target.closest('button')) {
        e.target.closest('button').blur()
      }
      if (e.target.parentElement?.closest('button')) {
        e.target.parentElement.closest('button').blur()
      }
    })
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0, 0) // Ensure page loads with scroll position at the top
    })
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0, 0) // Ensure page loads with scroll position at the top
    })
  },
  async mounted() {
    this.checkChannel()

    if (this.location) {
      await this.$api.getMenu(false)
    } else if (!this.location && this.channel === 'catering') {
      // Set a default catering menu id just to load homepage categories
      const data = await this.$api.getMenu(true, { menuId: '237039' })
      this.$store.commit('setMenu', data)
    }

    document.addEventListener('click', this.handleLinkClick);

    // Added Braze to the client's browser - allowing for testing through the console
    if(this.$braze) window.braze = this.$braze

    if (
      this.token &&
      (!this.token.provider || !this.token.provider.includes('oloauth'))
    ) {
      this.$api.logoutUser()
    }
    // if (!process.env.DISABLE_RECAPTCHA) {
    //   try {
    //     await this.$recaptcha.init()
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }
    if (this.$route.query.store) {
      await this.$api.getLocation(this.$route.query.store)
      const order = await this.$api.createOrder()
      this.$store.commit('setOrder', order)
    }

    // ADA fixes
    const a = document.createElement('a')
    const s = document.body.firstChild
    const linkText = document.createTextNode('Skip to main content')
    a.appendChild(linkText)
    a.title = 'Skip to main content'
    a.href = '#main'
    a.className = 'skiplink'
    s.parentNode.insertBefore(a, s)
    if (this.$route.path === '/locations') {
      this.locationRoute = true
      setTimeout(function() {
        const map = document.querySelectorAll('iframe')[0]
        map.setAttribute('name', 'Google map of Bojangles Locations')
      }, 2000)
    }

    this.locationGated = !this.$store.state.user && !this.$store.state.location

    // Set header and footer heights in vuex on initial load and resize
    this.$nextTick(() => {
      this.setHeights()
      window.addEventListener('resize',this.setHeights)
    })
  },
  destroyed() {
    document.removeEventListener('click', this.handleLinkClick);
    window.removeEventListener('resize',this.setHeights)
    window.removeEventListener('resize',this.setHeights)
  },
  methods: {
    ...mapMutations([ 'setChannel' ]),

    async checkChannel() {
      if (window.location.hostname.includes('catering') || window.location.href.includes('2001')) {
        this.$store.commit('setChannel', 'catering')
      } else {
        this.$store.commit('setChannel', 'default')
      }
      console.log('href location: ', window.location.href)
      console.log('active channel: ', this.channel)
    },
    closeErrors() {
      this.$store.commit('setErrors', [])
    },
    setHeights() {
      const headerHeight = document.querySelector('header').getBoundingClientRect().height+'px'
      const footerHeight = document.querySelector('footer').getBoundingClientRect().height+'px'
      this.$store.commit('setHeaderHeight', headerHeight)
      this.$store.commit('setFooterHeight', footerHeight)
    },

    handleLinkClick(event) {
      const target = event.target.closest('a');
      if (target && target.href && this.channel === 'catering') {
        console.log(target, 'target')
        console.log(target.href, 'target.href')
        const isExternal = !target.href.includes(window.location.host);
        console.log(isExternal, 'isExternal')
        if (isExternal && target.href.includes('locations')) {
          console.log('Go Go')
          window.open(target.href, '_blank');
          event.preventDefault();
        }
        else if (isExternal && this.order?.products?.length && this.goodToGo == false) {
          event.preventDefault();
          this.showExitModal();
        }
      }
    },
    showExitModal() {
      this.$refs.cateringExitModal.showModal = true;
    },

    goodToGoValue() {
      this.goodToGo = true;
    },
    async updateRoundupAmount() {
      const total = (this.order.totals.subtotal + this.order.totals.tax + this.order.totals.tip + this.order.totals.handoff - this.order.totals.discount)
      let amount = parseFloat((Math.ceil(total) - total).toFixed(2))
      if (total === 0) {
        amount = 1.00
      }
      await this.$axios({
        method: 'put',
        url: '/api/v2/carts/'+this.order.id+'/donations',
        data: {
          id: this.order.donations[0].id,
          amount: amount,
        }
      }).catch(err => {
        console.log(err.m)
        this.$store.commit('setErrors', [err.response.data.message])
      })
    }
  },
  watch: {
    '$route.path'(e) {
      this.$store.commit('setErrors', [])
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      this.locationRoute = this.$route.path === '/locations'
      this.checkChannel()
    },
    'order.totals': {
      handler() {
        if (this.order.totals.donations) {
          // Totals have changed update the donation amount
          this.updateRoundupAmount()
        }
      },
      deep: true
    }
  }
}
