
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      rememberChoice: false,
      selectedLocation: 'recent',
      selectedDeliveryAddress: null,
      locations: {
        nearest: {},
        recent: {}
      },
      deliveryAddresses: [],
      isLoading: true,
      geolocationError: null,
      showNearestLocation: false,
      hasRecentLocation: false
    }
  },
  computed: {
    ...mapState(['location', 'locationList', 'user', 'order', 'confirmLocationModalSection']),
    redirectUrl() {
      if (this.confirmLocationModalSection === 'order-again') {
        return '/order/checkout/'
      }
      return '/order'
    },
    shouldShowRadarMap() {
      return Object.keys(this.selectedLocationMap).length > 0
    },
    isDelivery() {
      return this.order && this.order.handoff === 'dispatch';
    },
    formattedNearestLocation() {
      const loc = this.locations.nearest;
      if (!loc.address) return 'Loading...';
      return loc.address && loc.city ? `${loc.address}, ${loc.city}, ${loc.state} ${loc.zip}` : 'Loading...';
    },
    formattedRecentLocation() {
      const loc = this.locations.recent;
      if (!loc.address) return 'No recent location';
      return loc.address && loc.city ? `${loc.address}, ${loc.city}, ${loc.state} ${loc.zip}` : 'No recent location';
    },
    selectedLocationMap() {
      if (this.isDelivery) {
        return this.deliveryAddresses.find(addr => addr.id === this.selectedDeliveryAddress).address
      } else {
        return this.locations[this.selectedLocation]
      }
    }
  },
  async mounted() {
    this.isLoading = true;

    if (this.isDelivery) {
      this.loadDeliveryAddresses();

      if (this.deliveryAddresses.length === 0) {
        this.redirectToOrderPage();
        return;
      }
    } else {
      this.getRecentLocation();

      if (!this.hasRecentLocation) {
        this.redirectToOrderPage();
        return;
      }

      this.getUserLocation();
    }

    this.$nextTick(() => {
      const locationConfirmModal = this.$refs.locationConfirmModal;
      if (locationConfirmModal) {
        locationConfirmModal.focus();
      }
      document.addEventListener('keydown', this.trapFocus);
      // need to scroll top due to the focus behavior
      window.scrollTo(0, 0);
    });
  },
  methods: {
    ...mapMutations(['setShouldOpenConfirmLocationModal', 'setConfirmLocationModalSection']),
    close() {
      document.removeEventListener('keydown', this.trapFocus);
      this.setShouldOpenConfirmLocationModal(false)
      this.setConfirmLocationModalSection(null)
    },
    redirectToOrderPage() {
      this.$router.push(this.redirectUrl)
    },
    async handleContinue() {
      if (this.rememberChoice) {
        this.$cookiz.set("location-confirmed", "true");
      }

      if (this.isDelivery) {
        if (this.selectedDeliveryAddress) {
          await this.processDeliveryLocation();
        }
      } else {
        await this.startPickupOrder()
      }

      this.close();
    },
    async startPickupOrder() {
      const selectedLocationData = this.locations[this.selectedLocation];
      if (selectedLocationData && selectedLocationData.id) {
        this.$store.commit('setLocation', selectedLocationData);
      }

      const menu = await this.$api.getMenu()
      if (this.order) {
        if (this.order.menu_id != this.location.menu_id) {
          await this.$api.transferOrder()
        }
      } else {
        await this.$api.createOrder()
      }
      if (menu) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'select_location',
          'location_name': this.location.address,
          'location_address': this.location.name,
        })

        this.redirectToOrderPage()
      }
    },
    getRecentLocation() {
      if (this.location) {
        this.locations.recent = this.location
        this.hasRecentLocation = true;
        this.selectedLocation = 'recent'; // Default to recent location
      } else {
        this.hasRecentLocation = false;
      }
    },
    getUserLocation() {
      if (!navigator.geolocation) {
        this.isLoading = false;
        return;
      }

      this.isLoading = true;

      navigator.geolocation.getCurrentPosition(
        async position =>  {
          await this.findNearestLocation(position.coords.latitude, position.coords.longitude);
        },
        error => {
          this.isLoading = false;
        },
      );
    },
    async findNearestLocation(lat, lng) {
      await this.$api.findLocations({ lat, lng })

      if (this.locationList.length > 0) {
        this.locations.nearest = this.locationList[0]
        this.showNearestLocation = this.isNearestLocationDifferent()
      }

      this.isLoading = false;
    },
    isNearestLocationDifferent() {
      if (!this.locations.nearest.id || !this.locations.recent.id) {
        return false;
      }

      return this.locations.nearest.id !== this.locations.recent.id;
    },
    loadDeliveryAddresses() {
      this.deliveryAddresses = [];

      if (this.order && this.order.address) {
        this.deliveryAddresses.push(this.order.address);
        this.selectedDeliveryAddress = this.order.address.id;
      }

      if (this.user && this.user.addresses && this.user.addresses.length > 0) {
        const userAddress = this.user.addresses[0];

        if (!this.deliveryAddresses.length || this.deliveryAddresses[0].id !== userAddress.id) {
          this.deliveryAddresses.push(userAddress);

          if (!this.selectedDeliveryAddress) {
            this.selectedDeliveryAddress = userAddress.id;
          }
        }
      }

      this.isLoading = false;
    },
    formatDeliveryAddress(address) {
      if (!address) return 'No address';

      let formattedAddress = address.address || '';
      if (address.apt) formattedAddress += `, ${address.apt}`;
      if (address.city) formattedAddress += `, ${address.city}`;
      if (address.state) formattedAddress += `, ${address.state}`;
      if (address.zip) formattedAddress += ` ${address.zip}`;

      return formattedAddress || 'Invalid address format';
    },
    async processDeliveryLocation() {
      const selectedAddress = this.deliveryAddresses.find(addr => addr.id === this.selectedDeliveryAddress);
      if (!selectedAddress) {
        this.$router.push("/locations?mode=delivery")
        return;
      }

      const request = await this.$api.findDeliveryLocations(selectedAddress)
      if (!request || (request.location && request.location.length < 1)) {
        this.$router.push("/locations?mode=delivery")
        return;
      } else {
        const location = await this.$api.getLocation(request.id)
        this.$store.commit('setLocation', location)
        await this.startDeliveryOrder(selectedAddress, location)
      }
    },
    async startDeliveryOrder(selectedAddress) {
      if (this.order && this.order.address && this.order.address.id === selectedAddress.id) {
        this.redirectToOrderPage()
      } else {
        let order = null
        if (this.order) {
          order = await this.$api.setAddress('dispatch', selectedAddress)
        } else {
          order = await this.$api.createOrder()
          order = await this.$api.setAddress('dispatch', selectedAddress)
        }
        if (order) {
          this.$store.commit('setOrder', order)
          this.redirectToOrderPage()
        }
        this.redirectToOrderPage()
      }
    },
    trapFocus(e) {
      const focusableElements = this.$refs.locationConfirmModal.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      if (e.key === 'Tab') {
        if (e.shiftKey) /* shift + tab */ {
          if (document.activeElement === firstFocusableElement) {
            e.preventDefault();
            lastFocusableElement.focus();
          }
        } else /* tab */ {
          if (document.activeElement === lastFocusableElement) {
            e.preventDefault();
            firstFocusableElement.focus();
          }
        }
      }

      if (e.key === 'Escape') {
        this.close();
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.trapFocus);
  }
}
