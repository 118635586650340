
  import Radar from '~/node_modules/radar-sdk-js'
  import 'radar-sdk-js/dist/radar.css'
  import maplibregl from 'maplibre-gl'
  import { mapState } from 'vuex'

  export default {
    props: {
      location: {
        type: Object,
        default: () => { },
      },
      offsetLng: {
        type: Number,
        default: 0.05,
      },
    },
    data() {
      return {
        map: false,
        marker: 'https://storyblok.pleinaircdn.com/f/110020/121x140/b4e43b243b/map-marker-large.png',
      }
    },
    mounted() {
      this.initialize()
      this.addMarker()
      this.$nextTick(() => {
        this.addAltToRadarLogos()
      })
    },
    computed: {
      mobile() {
        return (this.$mq == 'sm' || this.$mq == 'md')
      }
    },
    methods: {
      initialize() {
        Radar.initialize(process.env.RADAR_KEY)
        // prevent duplicate maps from rendering over each other
        if (this.map) {
          this.map.remove()
        }
        this.map = Radar.ui.map({
          container: 'map',
        })
        this.map.on('error', (err) => {
          console.error(err)
        })
      },
      addMarker() {
        const marker = document.createElement('div')
        marker.className = 'marker'
        marker.style.backgroundImage = 'url('+this.marker+')'
        if (this.mobile) {
          marker.style.width = '42.494px'
          marker.style.height = '52px'
        } else {
          marker.style.width = '55.628px'
          marker.style.height = '68.073px'
        }
        marker.style.backgroundSize = '100%'
        new maplibregl.Marker(marker).setLngLat([this.location.lng, this.location.lat]).addTo(this.map)
        this.map.setZoom(11)
        setTimeout(() => {
          const offsetLng = this.location.lng - this.offsetLng
          if (this.mobile) {
            this.map.setCenter([this.location.lng,this.location.lat])
          } else {
            this.map.setCenter([offsetLng,this.location.lat])
          }
        },250)
      },
      addAltToRadarLogos() {
        const radarLogos = document.querySelectorAll('#radar-map-logo img')
        radarLogos.forEach((logo) => {
          if (logo && !logo.getAttribute('alt')) {
            logo.setAttribute('alt', '')
          }
        })
      }
    },
    watch: {
      location: {
        handler() {
          this.addMarker()
        },
      },
    },
  }
